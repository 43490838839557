.enseignement-professeurs {

	.selection-professeurs {

		.select-professeurs-actifs {
			height: 300px;
		}

		button.submit-form {
			display: block;
			margin: 20px 0;
			padding: 10px;
		}
	}
}