.logitheque-content {

    .logiciel {
        padding: 20px 0;
        margin: 0;
        border-bottom: 1px solid lightgrey;
        transition-duration: 0.2s;

        .icone {
            text-align: center;

            img {
                width: 80px;
                height: 80px;
            }

            p {
                font-weight: bold;
            }
        }

        .description {
            padding: 10px 20px;
        }
    }

    .logiciel:hover {
        box-shadow: 1px 1px 3px #888;
    }

    .logiciel-hover {
        @extend .logiciel:hover;
        border: solid #888 2px;
    }

    .renouvellement-licence {
        position: absolute;
        left: 15px;
        bottom: 30px
    }

    @media screen and (min-width: 992px) {
        .renouvellement-licence {
            left: 167px;
        }
    }

    @media screen and (min-width: 1201px) {
        .renouvellement-licence {
            left: 200px;
        }
    }
}
