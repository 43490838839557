.livechat.index, .livechat-popin
{
    .vignette
    {
        position:relative;
        top: 15px;
        width: 100%;
        button
        {
            border: 0px;
            background-color: white;
            padding: 0px;
            width: 100%;
        }

        img
        {
            width: 100%;
        }

        .duree
        {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 3px 5px;
            background-color: rgba(0,0,0,0.7);
        }

    }

    .row
    {
        width: 95%;
        margin-left: 2.5%;
        padding: 5px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    .embedded
    {
        position: absolute;
        transform-origin: 50% 50%;
        top: 50%;
        left: 50%;
        width: 80%;
        height: 90%;
    }

    .chip
    {
        background-color: #e4e4e4;
        border-radius: 16px;
        padding: 3px 12px;
        color: rgba(0,0,0,0.6);
        display: inline-block;
        margin: 5px;
    }

     /* Index eleve - datatable
    ***********************************************************************************************/
    .efclive-liste {
        padding: 20px 0;
        .row {
            border: none;
            width: auto;
            margin-left: -15px;
        }
        .efclive-separateur{
            padding: 8px;
            vertical-align: top;
            border-top: 1px solid #ddd;
            cursor: pointer;
                    b {
                font-size : 1.25em;
                line-height: 1.6;
            }
            .bouton-formation{
                float: right;
                padding: 5px 10px;
                margin: 0;
                width: 30px;
            }
        }

        .dataTables_wrapper{
            padding: 20px 0;
            .col-sm-5 {
                width: 41.6666666667%;
            }
            .col-sm-6 {
                width: 50%;
            }
            .col-sm-7 {
                width: 58.3333333333%;
            }
            .col-sm-12 {
                width: 100%;
            }
        }

        .table-efclive {
            padding: 10px 0;
            tr{
                .titre-efclive {
                    font-weight: 600;
                }
            }
        }
        .vignette {
            top: auto;
            cursor: pointer;
            max-width: 300px;
            .vignette-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                transition: all 0.3s ease 0s;
                .fa-youtube-play {
                    transition: all 0.3s ease 0s;
                    font-size: 40px;
                    color: #bbbbbb;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    position: absolute;
                    opacity: 0.75;
                }
                &:hover {
                    background-color: rgba( 80, 80, 80, 0.3);
                    .fa-youtube-play {
                        opacity: 1;
                        color: #c4302b;
                    }
                }
            }
        }
        .chip {
            padding: 3px 5px;
            margin: 5px 0;
            h5 {
                font-size: 1rem;
            }
        }
    }
}
.livechat-popin {
    .video {
        max-width: 100%;
    }
    .liste-documents {
        padding: 30px 0;
        border-top: 1px solid #e5e5e5;
        h3 {
            margin-top: 0;
        }
    }
    .liste-cours {
        margin: 0 0 30px;
        padding: 30px 0;
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        h3 {
            margin-top: 0;
        }
    }

}

@media only screen and (max-width: 992px) {
    .livechat.index, .livechat-popin {
        .efclive-liste {
            border: none;
            .table-efclive {
                padding: 10px 0;
                th {
                    display: none;
                }
                tr {
                    margin: 0 0 20px;
                    .titre-efclive {
                        background-color: #1e5983;
                        padding: 5px;
                        color: #fff;
                        font-weight: 400;
                    }
                }
                td {
                    display: block;
                    border-top: none;
                    padding: 8px 0;
                    &:first-child {
                      display: none;
                    }
                    &:last-child {
                      padding-bottom: 2.5em;
                    }
                }
                .vignette {
                    max-width: 650px;
                }
            }
            .col-sm-7 { // Pagination datatable
                width: 100%;
            }
        }
    }
}