.questions_frequentes {

	.questions {
		margin-left: 30px;

		.question {
			border-top: 1px solid lightgrey;
			padding: 15px 10px;
			margin: 0;

			.fa.fa-chevron-down {
				float: right;
			}
		}

		.question-reponse:nth-child(even) {

			.question {
				background-color: #F3F3F3;
			}
		}

		.question:hover {
			font-weight: bold;
		}

		.reponse {
			margin: 10px 0 30px 30px;
		}
	}
}