.content.paie {

	h1, h2 {
		text-align: center;
	}

	.selection-options-paie {
		text-align: center;
		margin: 40px 0;

		.max-dropDown {
			height: auto;
		    max-height: 300px;
		    overflow-x: hidden;
		}

		.bouton {
			padding-top: 4px;

			a {
				display: block;
				width: 100%;
				height: 100%;
			}
		}

		.selection-mois {
			margin-left: 20px;
		}

		.selection-professeur {
			margin-right: 20px;
		}
	}

	table.paie {
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
		margin-bottom: 40px;

		thead {

			th {
				text-align: center;
				height: 30px;
			}

			.prestation {
				min-width: 250px;
			}

			.quantite {
				min-width: 100px;
			}

			.prix-unitaire {
				min-width: 150px;
			}

			.total {
				min-width: 150px;
			}
		}

		tr {
			border-bottom: solid 1px lightgrey;

			td {
				padding: 10px;
			}

			td:not(.prestation) {
				text-align: center;
			}
		}

		tbody {
			tr.important {
				font-weight: bold;
				font-size: 15px;
			}
		}

		tr:nth-child(even) {
			background: #F3F3F3;
		}
	}
}