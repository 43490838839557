
.container
{
  .navbar
  {
    select.form-control
    {
      min-width: 200px;
      margin: 8px 30px 0px 0px;
      display: inline-block;
    }

    li:first-child
    {
      margin-right: 15px;
    }
  }

  .filtre-container
  {
    .btn
    {
      margin: 5px;
    }
  }

  .serie-container
  {

    .none
    {
    }

    .sub-serie-container
    {

      margin-bottom: 10px;

      .header
      {
        border-radius: 5px;
        width: 100%;
        padding: 5px 10px;
        border-bottom-style: solid;
        border-bottom-width: 2px;
        cursor:pointer;

        button
        {
          float: right;
          background-color: inherit;
          border: 0px;
          margin-top: 10px;
          border-radius: 5px;

          i
          {
            border-radius: 5px;
            transition: all 0.5s ease;
          }

        }

      }

      .serie-content
      {
        .ligne
        {
          padding: 5px;
          display: inline-block;
          width: 100%;
          border-bottom-width: 1px;
          border-bottom-style: solid;
          transition:height 1s ease;

          i
          {
            padding: 5px;
          }

        }

        .ligne:last-child
        {
          border-bottom-width: 0px;
        }
      }
    }
  }

  .cours-en-ligne-support{
      p{
          font-weight : 600;
      }
      .tac-btn, .efclive-cours {
            background: none;
            border: 1px solid #9f9f9f;
            border-radius: 5px;
            display: inline-block;
            margin: 5px;
            font-style: italic;
            .fa {
                font-size: 20px;
                color: #383a4c;
            }
        }

        span.titre-efclive {
            margin-top: -6px;
            display: inline-block;
            vertical-align: middle;
            padding: 5px 0 3px;
        }
        .fa {
            font-size: 20px;
            padding: 0px 3px!important;

            &.fa-youtube-play {
                color: #e14747;
            }
        }
    }
}
