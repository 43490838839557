/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 9 mai 2016, 09:15:41
    Author     : l.rouquette
*/

.messagerie-menu-mobile.fixed
{
    position: fixed;
    top: 61px;
    z-index: 99;
    width: 100%;
}

.messagerie-menu-mobile
{
    box-shadow: 0px 0px 5px #AAA;
    border-bottom: 1px solid #BBB;

    i
    {
        padding-top: 5px;
        font-size: 24px;
    }
}

.popin-messagerie {
    .modal-dialog{
        width:70%;
        .modal-header {
            .title {
                text-align: center;
                h2{
                    margin-top:0;
                    margin-bottom:0;
                }
            }
        }
        .modal-body .messagerie-conversation{
            padding-top:0;
            padding-bottom:0;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.navigation-message {
    a {
        &:nth-child(1) {
            float:left;
        }
        &:nth-child(2) {
            float:right;
        }
    }
}


.messagerie {
    background-color: white;
    padding: 5px;

    a {
        color: inherit;
        display: inline-block;
        height: 100%;
        width: 100%;
        &:hover{
            text-decoration: none;
        }
    }

    .statut {
        position: relative;
        right: 50px;
        border-radius: 5px;
        min-width: 160px;
        padding: 0.5em 5px 0px 5px;

        i, p {
            display: inline-block;
            line-height: 1.2em;
        }
    }

    h3 {
        margin-top: 0px;
        padding-top: 0px;
    }

    h4 {
        margin: 0px 5px;
    }

    .auteur {
        display: inline-block;
    }

    .date-reception {
        display: inline-block;
    }

    .nouveau {
        background-color: #F3F3F3;
    }

    .sujet {
        display: inline-block;

    }

    .nouveau:hover {
        background-color: #EEEEEE;
    }

    .contenu-message {
        margin-left: 5px;
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        max-width: 525px;
        width: 100%;
        height: 1.4em; /* Fallback for non-webkit */
        line-height: 1.4em;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .colonne-message {

        width: 80%;

        a {
            padding-top : 10px;
            padding-left: 25px;
        }

        div:first-child {
            max-width: 80%;
            float: left;
        }

        div:first-child + div {
            width: auto;
            float: right;
            min-width: 120px;
            text-align: center;
        }
    }

    .colonne-avatar {
        display: block;
        clear: both;
        max-width: 180px;

        img {
            position: relative;
            width: 80px;
            height: 80px;
            border-radius: 50px;
            border-width: 3px;
            border-style: solid;
            border-color: white;;
            background-color: white;
            top: 5px;
        }
    }

    .colonne-checkbox  {

        height: 100%;

        a {
            text-decoration: none;
            width: 20%;
        }

        i {
            font-size: 32px;
            display: block;
            margin-top: 30px;
        }
    }

    .date-action {
        width: 120px;
        display: inline-block;
        text-align: center;
    }

    .desktop {
        display:block;
    }

    .phone {
        display:none;;
    }
}

@media only screen and (min-width: 1201px) {
    .messagerie {
        .colonne-checkbox {
            padding-left: 10px;
        }
    }
}

/* Large Devices, Wide Screens (992 < screen < 1200) [md] */
@media only screen and (max-width: 1200px) {
    .messagerie {
        .colonneCheckbox {
            padding-left: 8px;
        }
    }
}

/* Ecran en dessous de 1024 (screen < 1024) */
@media only screen and (max-width: 1024px) {
    .messagerie {

        .statut
        {
            position: relative;
            right: 25px;
            padding-bottom: 7px;
            padding-top: 7px;
            i.fa, p {
               font-size: 14px;
            }
        }
        .colonne-avatar {
            img {
                width: 80px;
                height: 80px;
            }
        }

        .colonne-checkbox {
            padding-left: 6px;
        }
    }
}
/* Medium Devices, Desktops (768 < screen < 992) [sm] */
@media only screen and (max-width: 992px) {
    .messagerie {
        .colonne-checkbox {
            padding-left: 4px;
        }

        .colonne-avatar {
            img {
                width: 70px;
                height: 70px;
            }
        }

        h3 {
            width: 100%;
        }

        .colonne-message {
            width: 100%;
            min-width:100%;
            div {
                float: left;
            }
        }

        .text-center {
                width: 128px;
                height: 19px;
                float:right;
                position: relative;
                right: 25px;

            p, i {
                display: inline;
            }
        }
    }
}

/* Small Devices, Tablets (480 < screen < 768) [xs] */
@media only screen and (max-width: 768px) {

    .messagerie {
        .auteur {
            display:block;
            width:100%;

            }
        .colonne-message {

            div:first-child {
                min-width:100%;
            }

            .text-center {
                float:right;
                width: 120px;
                right:-25px;
                margin-bottom:10px;
                margin-top:10px;

                .statut {
                    padding: 6px 12px;
                    float:right;

                    i {
                        display:none;
                    }
                }
            }
        }

        .sujet {
            float:left;
            display:block;
            position:relative;
            top:-15px;
            margin-left: 0px;
            min-width:100%;
        }

        .colonne-checkbox {
            padding-left: 0px;
            max-width:35px;
            i {
                font-size: 26px;
            }
        }

        .colonne-avatar {
            margin-right:-20px;
        }

        .date-reception {
            display:block;
            float: left;
            position:relative;
            top:-15px;
            left: 7px;
            min-width:100%;
        }

        .contenu-message {
            margin-left: 0px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .date-action {
            display: none;
        }

        .desktop {
            display:none;
        }

        .phone {
            display:block;
        }
    }

}
/* Extra Small Devices, Phones (320 < screen < 480) */
@media only screen and (max-width: 480px) {

    .navigation-message {
        a {
            &:nth-child(1) {
                display:right;
            }
            &:nth-child(2) {
                float:right
            }
        }
    }

    .messagerie {
        .colonne-avatar {
            display:none;
        }

        .colonne-message {
            position : relative;

            div:first-child + div {
                width: auto;
                min-width: 120px;
                text-align: center;
                float:right;
            }

            a {
                padding-left: 0px;
            }

            .text-center {
                width: 100px;
                right:10px;
            }

            .statut {
                right:-25px;
            }

            .auteur {
                .statut {
                    padding: 6px;
                }
            }
        }
    }
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
    .messagerie {
        .date-reception {
            display:block;
            top: -10px;
            float: left;
        }

        .text-center {
            width: 66px;
            left: 44px;

        }
        .auteur {
            .statut {
                padding: 0px;
            }
        }
    }
}


.textarea-message{
    border:1px solid #ccc !important;
}

.erreur-message::-webkit-input-placeholder {
    color: #e86868;
    opacity: 0.7;
}
.eleve-message{
    width:220px !important;
}
.erreur{
    color: #e86868;
    margin-left:15px;
    position:relative;
    top:-5px;
}

.empecher_message {
    color:#FFFFFF;
    font-size:16px;
    background-color:#f15757;
    margin-top:50px;
    margin-bottom:20px;
    padding: 20px 20px;
    text-align:center;
}

.modifier-message-interne {
    cursor:pointer;
    font-size:20px;
}
.textarea-edition-message {
    width:100%;
    min-height: 150px;
    background-color: #64667d;
}
.btn-edition-message {
    position:relative;
    top:0px;
    width:100px;
    display:inline-block;
    margin:5px;

    &:active {
        margin:5px !important;
    }
}

div.container-btn-edition-message {
    float:right;
    margin-right:
    20px;
    width:auto;
    padding:16px 0px;
}

.etat-ticket{
    .statut{
        max-width:200px;
        top: 0;
        right:0;
    }
}
.filtre-messagerie{
    padding-bottom: 5px;
}
.type-utilisateur-message{
    padding: 2px;
    border-radius: 50%;
    color: white;
    height: 20px;
    width: 22px;
    font-size: 12px;
    text-align: center;
    display: inline-block;
    &.moderateur{
        background: #F6CEF5;
    }
    &.eleve{
        background: #1D7096;
    }
    &.professeur{
        background: #A5C84A;
    }
}

.ticket td:first-child, .legende-action-color {
    padding: 0;
    text-align: center;
    vertical-align: middle;
    background-color: #C7CBF3;
}

.ticket td.no-bg {
    background: none;
}

.legende-messagerie{
    display: block;
    width: 200px;
    position: absolute;
    left: -250px;
    top: 92px;

    .titre-legende {
        font-weight : bold;
        background : white;
        text-align: center;
        border: 1px solid grey;
    }
    .legendEtat {
        padding: 0 5px;
    }
    .legendEtat,
    .legende-color,
    .titre-legende {
        height: 40px;
        line-height: 40px;
    }
    .legende-etat{
        margin-top: 30px;
    }

    .legende-border{
        border: 1px solid grey;
    }
    .legende-action{
        background : white;
        .legende-color {
            display: inline-block;
            width: 40px;
            vertical-align: middle;
            border-right: 1px dotted grey;
            i {
                vertical-align: middle;
            }
        }
    }
}
/*Tri sur entete côté modo*/
.trie-entete {
    position: relative;
    .fa {
        color : #D0D0D0;
        position: absolute;
        right: 0px;
        font-size: 18px;
        &.fa-asc{
            top:0px;
        }
        &.fa-desc{
            bottom:0px;
        }
        &.active {
            color: #383a4c;
        }
    }
}

.table.messagerie > thead > tr > th {
    padding: 8px 3px;
}

.ticket-liste .dataTables_wrapper {
    .col-sm-12 {
        width: 100%;
    }

    .col-sm-7 {
        width: 49%;
    }

    tr.ticket.even {
        background-color: #e4e4e4;
    }
    thead > tr > th {
        padding: 8px 0px 8px 3px;
    }

    table.dataTable thead > tr > th.sorting {
        padding-right : 0;
    }
}