.content.questionnaire.index {
  .question-header {
    border-radius: 8px;
    background-color: #DDD;

    h3 {
      padding: 10px 15px;
    }
  }
  .panel-content {
    .row
    {
      padding: 5px;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      width: 100%;
      margin-left: 0%;
    }
    .row:last-child
    {
      border-bottom: 0;
    }
  }
}