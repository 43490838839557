.mooc{
    a {
        color : #23527c;
    }

    .bouton-mooc {
		padding: 8px;
	    line-height: 1.428571429;
        vertical-align: top;
        border-top: 1px solid #ddd;
        margin : 0;
        cursor : pointer;
        .bouton-plus-mooc {
            float : right;
            padding: 2px 8px;
            margin: -2px 0 0 0;
        }
    }
    .mooc-content{
        display : none;
    }
    .video-content {
        margin-left: 50px;
        font-size: 20px;
        margin-top: 20px;
        .fa {
            font-size : 2em;
            vertical-align : middle;
        }
    }
    .video-index{
        display : block;
    }
    .mooc-video {
        font-size: 16px;
        margin-left: 10px;
        cursor: pointer;
    }
    .objectifs {
        margin : 14px 0 0 40px;
    }

    .ressources {
        h5 {
            color : #308DD0;
        }
    }

    @media screen and (max-width: 767px) {
        .video-content {
            margin-left : 10px;
        }

        .fa {
            display : inline-block;
        }

        .mooc-video {
            font-size : 14px;
            display: inline-block;
            max-width: 80%;
            height: 40px;
            vertical-align: middle;
        }

        .objectifs {
            margin-left : 0;
        }
    }
}