.select2-container--default .select2-selection--single {
	background-color : transparent;
	border: none;
	text-align: left;
  	.select2-selection__rendered {
	  	line-height : 1.428571429;
	}
}

.select2-container--default .select2-results__option[aria-selected=true] {
		background-color: #5fe08a;
}