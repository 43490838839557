
/*Version Desktop*/

$epaisseurBordureDesktop: 3px;
$epaisseurBordurePhone: 3px;

a.logo-chat {
	position:fixed;
	right:4%;
	bottom:0px;
	width:350px;
	height:50px;
	background-color:#484343;
	opacity:0.8;
	z-index:100000000000000000;

	&:hover {
		font-decoration:none !important;
		opacity:0.9;
	}
}


span.logo-chat {
	display:block;
	opacity:1 !important;
	margin:auto;
	padding-top:7px;
	text-align:center;
	color:white;
	font-size:25px;
}


#headerDesktop {
	background: white;

	.logo-creacours {
		padding:5px;
	}

	.colored {
		border-bottom-width: $epaisseurBordureDesktop;
		border-bottom-style: solid;
		color: $rougeCreacours !important;
	}
	.dropdown:hover .dropdown-menu {
		display: block;
	}

	.subItem {

		input {
			display: inline-block;
			width: 100%;
			background-color: white;
			border: none;
			padding: 5px 10px 5px 0px;
		}

		input:hover {
			background-color: rgb(245,245,245);
		}

	}
}

#headerDesktop li:not(.active) a.mainItem,
#headerDesktop li:not(.active) button.mainItem

{
	border-bottom: $epaisseurBordureDesktop solid $rougeCreacours;
}

#headerDesktop .navbar-nav
{
	width: 100%;
	float: none;
	text-align: center;
}



#headerDesktop .navbar-nav li
{
	float: none;
	display: inline-block;

	.subItem {
		display: block;
		width: 100%;
	}
}

#headerDesktop .subItem
{
	margin-top: 10px;
}

#headerDesktop #top
{
	width: 60%;
	margin-left: auto;
	margin-right: auto;
}

.not-eleve#headerDesktop #top{
    position: relative;
    .type-compte{
	    position: absolute;
        right: 600px;
        top: 18px;
        font-size: 30px;
    }
}


#headerDesktop #notifications
{
	display: inline-block;
	float: right;
	margin-top: 30px;
}

#headerDesktop #notifications i
{
	font-size: 25px;
	margin-right: 20px;
}

#headerDesktop .notification
{
	display: inline-block;
	width: 66px;
	text-align: center;
}

#headerDesktop .notificationBubble
{
	display: inline-block;
	padding: 0 0px;
	position: relative;
	top: -15px;
	right: 22px;
	border-radius: 50%;
	color: white;
	font-size: 10px;
	height: 17px;
	width: 17px;
	text-align: center;
}

#headerDesktop #infosUtilisateur
{
	display: inline-block;
	float: right;
	padding-top: 10px;
	border-left: 2px solid lightgrey;
	border-right: 2px solid lightgrey;
	height: 80px;
}

#infosUtilisateur .dropdown-menu
{
	margin-top: -0px;
}

#headerDesktop #infosUtilisateur:hover
{
	cursor: pointer;
}

#headerDesktop #nomUtilisateur
{
	margin-left: 20px;
}

#headerDesktop #avatar
{
	margin-left: 20px;
	height: 60px;
	margin-right: 20px;
}

#headerDesktop #logoEFC
{
	height: 80px;
}

header .fixed
{
	position: fixed;
	top: 0;
	z-index: 99;
	width: 100%;
}

@media only screen and (max-width: 1440px){
	#headerDesktop{
		&.not-eleve{
			#top {
	  	  		width: 100%!important;
	  	 	}
		}
	}
}

@media only screen and (max-width:992px){
	#headerDesktop{
		&.not-eleve{
	   	 #notifications{
	    		display: inline-block;
    			float: right;
    			margin-top: -50px;
    			margin-right: 240px;
	 	   }
		}
	}
}
@media only screen and (max-width:550px){
	#headerDesktop{
		&.not-eleve{
			#infosUtilisateur{
				#avatar{
					width: 0;
					margin-left: 5px;
					margin-right: 0px;
				}
			}
			#nomUtilisateur{
				margin-left: 5px;
			}
			#notifications{
				display : none;
			}
		}
	}
}

/*Modification du style bootstrap*/
/* navbar */
.navbar-custom {
    background-color: $rougeCreacours;
    border-color: $rougeCreacours;
    margin-bottom: 5px;
}
.navbar-custom .mainItem{
    color: #DDD !important;
}
/* title */
.navbar-custom .accueilMenu > a,
.navbar-custom .accueilMenu > button{
    font-weight: bold;
}
/* link */
.navbar-custom .navbar-nav > li > a:hover,
.navbar-custom .navbar-nav > li > a:focus,
.navbar-custom .navbar-nav > li > button:hover,
.navbar-custom .navbar-nav > li > button:focus {
	background-color: $rougeCreacours;
}
.navbar-custom .navbar-nav > .active > a,
.navbar-custom .navbar-nav > .active > a:hover,
.navbar-custom .navbar-nav > .active > a:focus,
.navbar-custom .navbar-nav > .active > button,
.navbar-custom .navbar-nav > .active > button:hover,
.navbar-custom .navbar-nav > .active > button:focus {
    background-color: $rougeCreacours;
    font-weight: bold;
}
.navbar-custom .dropdown-menu
{
	top:52px !important;
}





/*Version mobile*/

#headerPhone
{
	padding-top: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #DDD;
	box-shadow: 0 0px 10px #AAA;
	background: #EEE;
}

#headerPhone #notifications
{
	display: inline-block;
	float: right;
	margin-top: 5px;
}

#headerPhone #notifications i
{
	font-size: 25px;
	margin-right: 5px;
	margin-top: 5px;
}

#headerPhone .notification
{
	display: inline-block;
	width: 50px;
	text-align: center;
}

#headerPhone .notificationBubble
{
	display: inline-block;
	padding: 0 4px;
	position: relative;
	top: -20px;
	right: 9px;
	border-radius: 50%;
	color: white;
	font-size: 10px;
	height: 16px;
	width: 16px;
	text-align: center;
}

#headerPhone #logoEFC
{
	margin-left: 10px;
	display: inline-block;
	height: 40px;
}

#headerPhone #menuButton
{
	display: inline-block;
	padding: 9px 16px;
	float: left;
	background: $rougeCreacours;
	border: 1px solid $rougeCreacours;
	color: white;
	font-weight: bold;
	margin-left: 25px;
}

#headerPhone #st-trigger-effects
{
	display: inline-block;
	float: left;
}

#mainMenuPhone .subItem a,
#mainMenuPhone .subItem button
{
	padding-left: 50px;
}

#mainMenuPhone #avatarPhone
{
	height: 55px;
	margin-right: 10px;
	vertical-align: top;
	display: inline-block;
}

#mainMenuPhone {

	.mainItem.more {
		i.rotate {
			transform:rotate(180deg);
		}
	}

	.mainItem {
		i.rotate {
			transition: all 0.5s ease;
		}
	}
}

#infosUtilisateurPhone
{
	padding: 15px;
}

#infosUtilisateurPhone span
{
	color: #DDD;
	display: inline-block;
	width: 60%;
}

#closeMenu i
{
	float: right;
	font-size: 25px;
	color: #DDD;
	padding: 10px 10px 0 10px;
}

.st-menu .active .mainItem{
	background: $grisMenu;
	color: #fff;
}

.st-menu .colored .mainItem
{
	border-right-width: $epaisseurBordurePhone;
	border-right-style: solid;
	background-color: $rougeCreacours;
}

.st-menu .mainItem i
{
	float: right;
}

.st-menu .deconnexionMenu i
{
	font-size: 20px;
	padding-right: 20px;
}
