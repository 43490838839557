/*Platforme devoirs*/


.tutosPlateformeDevoirs
{
	padding: 10px 30px;

	.titre
	{
		font-size: 25px;
		display: inline-block;
	}

	b
	{
		display: block;
	}

	.liensVideos
	{
		margin-left: 80px;
		position:relative;
	}

	.lienVideo
	{
		width: 80px;
		height: 80px;
		margin-right: 20px;
	}

	.lienVideo a
	{
		text-align: center;
		display: inline-block;
		padding-top: 3px;
		color: white;
	}

	.lienVideo button
	{
		text-align: center;
		display: inline-block;
		padding: 3px;
		color: white;
	}

	.lienVideo span
	{
		display: block;
		text-align: center;
		font-size: 13px;
		margin-top: 5px;
	}

	.lienVideo i
	{
		padding-top: 3px;
		display: block;
		font-size: 30px;
	}
}

/*Actualités*/

.lirePlus{
	z-index:999;
	border:1px solid black;
	position:absolute;
	bottom:7px;
	right:15px;
	display:block;
	padding:10px;
	background: linear-gradient(#434444 50%, #212221 50%);
}

.actualites-wrapper
{
	display: inline-block;
	float: left;
	text-align: center;

	.actualites {
		padding: 10px 5px;
		display: inline-block;
		width: 80%;
		.item {
			border-radius: 5px;
			padding: 5px 30px;
			overflow: auto;
			text-align: left;
	 	}

	 	p {
	 		font-size: 13px;
	 		width: 100%;
	 	}

		h2, p {
			text-align: left;
			margin-bottom: 40px;
		}

		h2 i {
			margin-left: 5px;
			margin-right: 5px;
		}

		.bouton-dual-color-dark {
			height: 25px;
			margin-top: 20px;
			padding-top: 2px;
			float: right;
			border: none;

			a, button {
				color: white;
				padding-left: 10px;
			}
		}

		.carousel-indicators {
			top: 100%;
			bottom: auto;
			li{
				background: rgba(0,0,0,0.5);
 			}
		}

		.carousel-control {
			background: none;

			span {
				font-size: 40px;
				margin-top: -25px;
				color: #222222;
			}
		}

		a.left {
			left: auto;
			right: 100%;
		}

		a.right {
			left: 100%;
			right: auto;
		}

		a.carousel-control {
				width: 25px;
		}
	}
}

.listeSupport{
	display: block;
	margin-bottom: 5px;
}

.actualites-importantes
{
	.item {
		border: 5px solid $actuPrimary;
		color: $black;
 		background: $actuSecondary !important;
 		h2.actu-title {
 			color: $actuPrimary;
 		}
 	}
}

.actualites-simples
{
	.item {
		border: 3px solid $actuPrimary;
		color: $black;
 		background: $white !important;
 		h2.actu-title {
 			color: $actuPrimary;
 		}
 	}
}


.calendrier
{
	display: inline-block;
	padding-left: 10px;
	margin-top: 20px;
	width: 33%;
	float: left;
	img {
		width: 100%;
	}
}


.boutons-raccourcis-wrapper
{
	padding: 35px 5px;
	width: 100%;
	min-height: 100%;
	display: inline-block;
	float: left;
	vertical-align: top;
}


.avancement-devoirs
{
	.titre {
		text-align: center;
	}
}

.sider-right .content {
	padding-left: 10px;

	h2 {
		font-size:23px;
	}

	.questions-frequentes
	{
	 	p {
	 		font-size: 13px;
	 	}

	 	ul {
	 		list-style-type: none;
	 		padding: 0;
	 	}

	 	li {
	 		padding: 10px 0;
	 		i {
		 		display: inline-block;
		 		float: left;
		 		padding-left: 5px;
		 		margin-top: 3px;
		 	}

		 	a,button {
		 		display: inline-block;
		 		text-align: left;

		 		h4 {
		 			margin: 0;
		 			float:left;
		 		}
		 	}

		 	div {
		 		padding-left: 20px;
		 	}

		 	p {
		 		padding-top: 10px;
		 	}
		 }
	}
}


.accueil.professeur, .accueil.moderateur {

	.boutons-raccourcis-wrapper {
		width: 100%;
		float: none;
		text-align: center;

		.bouton-raccourcis {
			margin: 15px;
		}
	}
}

.boutons-raccourcis-wrapper {

		.bouton-raccourcis > .bouton-dual-color-light {
			border-color: $blue-dark-2 !important;
		}
	}

@media only screen and (max-width: 1200px) and (min-width: 992px)
{
	.boutons-raccourcis-wrapper {
		padding: 50px 0 0 0 !important;

		.bouton-raccourcis {
			margin-left: 15px !important;
			margin-top: 15px !important;
		}
	}
}

@media only screen and (min-width: 992px)
{
	.actualites-wrapper {
		.item {
			height: 280px;
		}
	}

	.boutons-raccourcis
	{
		.bouton-raccourcis {
			display: inline-block;
			vertical-align: top;
			margin-left: 30px;
			margin-top: 30px;

			.bouton-dual-color-light {
				width: 135px;
				height: 135px;
				float: left;
				position: relative;

				a {
					text-align: center;
					width: 100%;
					display: inline-block;
					padding: 3px;
				}

				span {
					display: block;
					text-align: center;
					font-size: 16px;
					margin-top: 15px;
				}

				i {
					padding-top: 20px;
					display: block;
					font-size: 35px;
				}

				.notificationBubble {
					display: inline-block;
					padding-top: 1px;
					position: absolute;
					top: -7px;
					right: -7px;
					border-radius: 50%;
					color: white;
					font-size: 13px;
					height: 22px;
					width: 22px;
					text-align: center;
				}
			}
		}
	}

	.tutosPlateformeDevoirs {
		.desktop {
			display: inline !important;
		}
		.liensVideos {
			display: inline-block;
		}
	}
}

#ouverture-inscrit {
	display: none;
}

#inscrit-modal{
	a {
		padding: 8px 20px;
		&.inscription{
			margin-right: 35px;
		}
		.fa{
			padding-right: 10px;
		}
	}
	a, a:hover, a:focus {
		text-decoration: none;
	}
	.modal-dialog{
		margin: 200px auto 0;
		.modal-content {
			border-radius: 0;
		}
	}
	.modal-header{
		font-size: 16px;
		h2{
			font-size:30px;
			margin-bottom: 0;
		}
		h3{
			font-size: 20px;
		}
		button.close {
   			color : $grisCreacours;
   			opacity: 0.8;
			span{
				font-size: 45px;
   				float: right;
   				margin-top: -15px;
   				margin-right: -5px;
   			}
		}
		p {
			margin-bottom: 15px;
		}
	}
	.modal-body{
		background-image: url("../../img/accueil/popin-illustration.jpg");
    	background-repeat: no-repeat;
    	background-position: center;
    	height: 150px;
    	background-size: cover;

    	.acces-cours{
			margin-top: 40px;
			font-size: 16px;
			span {
				padding:10px 20px;
				cursor: pointer;
				border-radius: 25px;
			}
    	}
	}
}

@media only screen and (max-width: 992px)
{

	.actualites-wrapper {
		width: 100%;

		h2 {
			width:90%;
		}

		.item {
			min-height: 240px;
			max-height: 240px;
		}

		.lire-plus {
			border: none;
			text-align: center;
			display: block;
			padding-top: 8px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 10px;
			// margin-bottom: 30px;
			font-size: 15px;
			font-weight: bold;
			width: 110px;
			height: 40px;
			margin-top:25px;
		}
	}

	.actualites-raccourcis {
		padding: 0 20px;

		.actualites {
			margin-top: 20px;
			margin-left: 0px !important;
			padding: 0;
			width: 100% !important;

			.item {
				min-height: 240px;
				overflow: auto;

				p {
					margin-bottom: 50px;
				}

				.indication-enlever-favoris {
					display: none;
				}

				.suite {
					display: none;
				}


			}
		}
	}

	.boutons-raccourcis-wrapper {
		width: 100%;
		padding: 0;
		margin: 20px 0;

		.boutons-raccourcis {
			width: 100%;
			padding: 0;

			.bouton-raccourcis {
				margin: 10px 0 0 0;
				width: 100%;

				.bouton-dual-color-light {
					width: 100%;
					height: 70px;
					position: relative;
					padding-top: 22px;
					border-color: #BCBCBC;

					a {
						width: 100%;
						font-size: 18px;
						margin-left: 20px;
					}

					span {
						display: inline-block;
						font-weight: bold;
						margin-left: 15px;
					}

					i {
						display: inline-block;
					}

					i.phone {
						display: inline-block !important;
						float: right;
						margin: 3px 50px 0 0;

					}

					.notificationBubble {
						display: inline-block;
						padding-top: 1px;
						position: absolute;
						top: 23px;
						right: 80px;
						border-radius: 50%;
						color: white;
						font-size: 13px;
						height: 22px;
						width: 22px;
						text-align: center;
					}
				}
			}
		}
	}

	.tutosPlateformeDevoirs {
		width: 100%;
		padding: 20px 5px;

		.titre {
			padding: 20px 10px;
			width: 100%;
			margin: 0;
			border-style: solid;
			border-width: 1px;
			border-radius: 5px;
			font-size: 14px;
			font-weight: bold;

			b {
				display: inline;

				.phone {
					display: inline !important;
					margin-left: 5px;
				}
			}
		}

		.liensVideos {
			margin: 0;
			width: 100%;

			.lienVideo {
				margin-top: 10px;
				width: 100%;
				height: 50px;
				padding: 10px 10px;

				a {
					text-align: left;
				}

				i {
					display: inline;
					font-size: 17px;
				}

				span {
					display: inline;
					font-size: 17px;
					margin-left: 10px;
				}
			}
		}
	}

	.questions-frequentes {
		padding: 0 20px 0 15px;

		.question {
			border-style: solid;
			border-width: 1px;
			border-radius: 5px;
			margin-top: 10px;
		}
	}
}

.modal-body {

	.video {
		margin: 30px 0;
		height: 450px;
		width: 800px;
	}
}


@media only screen and (max-width: 992px) {
	.modal-body {

		.video {
			margin: 20px 0;
			height: 281.25px;
			width: 500px;
		}
	}
}

@media only screen and (max-width: 768px) {
	.modal-body {

		.video {
			height: 168.75px;
			width: 300px;
		}
	}

	#inscrit-modal{
		.modal-dialog{
			margin: 80px auto 0;
		}
	}
}

