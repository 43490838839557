$blue : #1e5983;

.maj-liste {
	padding: 20px 0;
	.maj-separateur{
		padding: 8px;
		vertical-align: top;
		border-top: 1px solid #ddd;
		cursor: pointer;
				b {
			font-size : 1.25em;
			line-height: 1.6;
		}
		.bouton-colis{
			float: right;
			padding: 5px 10px;
			margin: 0;
			width: 30px;
		}
	}
	#table-maj-prof {
		display: table;
	}

	.dataTables_wrapper{
		padding: 20px 0;
		.col-sm-5 {
			width: 41.6666666667%;
		}
		.col-sm-6 {
			width: 50%;
		}
		.col-sm-7 {
			width: 58.3333333333%;
		}
		.col-sm-12 {
			width: 100%;
		}
	}

	.table-maj {
		display: none;
		padding: 10px 0;
		tr{
			&.maj{
				border : {
					left : 6px solid transparent;
				}
				.btn-download-maj{
					background: $blue;
					color: white;
					border : {
						color: $blue;
					}
				}
				&.downloaded{
					border : {
						color:  $blue;
					}
					.btn-download-maj{
						background: white;
						color: $blue;
						border : {
							color: $blue;
						}
					}
				}
			}
		}
	}
}

