.dossier_eleve {

	.bouton.rechercher {
		padding: 8px 15px;
		text-align: center;
	}

	.bouton.rechercher-autre-eleve {
		width: 210px;
		text-align: center;
		height: 30px;
		padding-top: 4px;
		margin-top: 5px;
	}

	.eleve {
		margin-left: 30px;
	}

	.eleve-input {
		margin: 20px 0;
	}

	li {
		margin-bottom: 10px;
	}

	.fa-chevron-down {
		float: right;
	}

	.infos-eleve {
		padding-left: 40px;

		.left {
			display: inline-block;
			width: 80%;
			vertical-align: top;

			.nom-eleve {
				font-size: 25px;
				font-weight: bold;
			}

			.telephone {
				font-size: 20px;
			}

			.space {
				margin: 0 10px;
			}
		}

		.right {
			display: inline-block;
			width: 19%;
			vertical-align: top;
			text-align: center;
			padding-right: 20px;

			.avatar-eleve {
				width: 175px;
				height: 175px;
			}

			.connexion-eleve {
				margin-top: 20px;
			}
		}
	}

	.observations {
		> h3 {
			padding-left: 40px;
		}

		.contenu-observations-wrapper {
			padding: 20px 0;

			.contenu-observations {
				width: 90%;
				margin-left: auto;
				margin-right: auto;
				border: 1px solid lightgrey;
				border-bottom: none;

				.observation {
					padding: 20px;
					border-bottom: solid 1px lightgrey;

					.auteur-observation {
						width: 20%;
						display: inline-block;
						vertical-align: top;

						.nom-auteur, .appel {
							font-weight: bold;
						}
					}

					.texte-observation {
						width: 79%;
						display: inline-block;
						vertical-align: top;
						padding-left: 15px;
					}
				}

				.deposer-observation {
					padding: 20px;
					border-bottom: 1px solid lightgrey;

					.left {
						width: 80%;
						display: inline-block;
						vertical-align: top;

						.observation-input {
							height: 100px;
						}
					}

					.right {
						width: 19%;
						display: inline-block;
						vertical-align: top;
						padding-left: 20px;

						.bouton {
							padding: 8px 15px;
							text-align: center;
						}
					}

				}

				.observation:nth-child(even) {
					background: #F3F3F3;
				}
			}
		}
	}

	.avancement-devoirs {
		
		> h3 {
			padding-left: 40px;
		}

		.contenu-avancement-devoirs-wrapper {
			padding: 20px 0;

			.table-avancement-devoirs {
				margin: 10px auto;
			}
		}
	}

	.login-eleve {

		.infos-login-eleve {
			padding-left: 20px;

			> p {
				margin: 0;
				padding: 10px 0;
			}

			.ouvrir-aide {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				display: block;
				text-align: center;
			}
		}
	}

	.inscriptions {

		.inscription.row {
			padding: 10px 0 10px 20px
		}
	}
}