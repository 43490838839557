.partenaires {
	text-align: center;

	.partenaire {
		width: 280px;
		height: 80px;
		border: 1px solid lightgrey;
		display: inline-block;
		border-radius: 5px;
		vertical-align: top;
		margin: 10px;
		padding: 10px;
		text-align: center;

		img {
			max-width: 100%;
			height: 100%;
		}
	}
}