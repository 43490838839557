/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 9 mai 2016, 14:13:42
    Author     : l.rouquette
*/

.messagerie-conversation {
    .message .contenu {
        a {
            color: #57b1ff;
            text-decoration: underline;
        }
    }

    .pagination {

        li {
            display: inline;
            padding: 0px;
            margin: 0px;

            button {
                background-color: white;
                border-width: 1px;
                border-style: solid;
                height: 30px;
                width: 34px;
                padding: 5px 10px;
                display: inline;
                margin: 0px;
                position: relative;
                float: left;
            }

            form {
                display: inline;
                padding: 0px;
                margin: 0px;
                position: relative;
                float: left;
            }
        }

        a.icone-msg {
            border-color: #DDD;
        }

        li:first-child form button {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-left: 1px solid #DDD !important;

        }

        li:last-child form button {
            border-left: 0px;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }

    .piece-jointe-container {
        input.input-file-message {
            height: 36px;
            margin-right:10px;
        }

        a {
            background-color:#E14747;
            height: 36px;
            color:white;
        }
    }

    .phone {
        display:none;
    }

    a.desktop {
        width: 205px;
        display:block;
    }

    .informations {
        display: inline-block;
        width: calc(25% - 20px);
        padding: 5px 10px;
        border-radius: 5px;
        float: left;

        h4 {
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            display: inline-block;
            overflow: hidden;
        }

        h5.info-piece-jointe {
            a  {
                border: 0px;
                display:inline-block;
                width:100%;
                position:relative;
                white-space:nowrap;
            }
            span {
                position:absolute;
                left:16px;
                width:80%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .conversation {
        position: relative;
        display: inline-block;
        width: calc(75% - 5px);
        float: left;
        margin-left: 5px;
    }


    .forum {

        display: inline-block;
        width: calc(33% - 5px);
        float: left;
        margin-left: 5px;
    }

    .message {
        position: relative;
        min-height: 6em;

        .contenu {
            display: inline-block;
            border-radius: 5px;
            border-style: solid;
            border-width: 1px;
            padding: 16px 30px;
            max-width: 85%;
            word-wrap: break-word;
        }

        img {
            position: absolute;
            width: 60px;
            height: 60px;
            border-radius: 30px;
            border-width: 3px;
            border-style: solid;
            background-color: white;
            top: 5px;
        }
    }

    .expediteur {

        margin: 10px 20px 10px auto;

        .contenu {
            float: right;
            right: 0;
        }

        img {
            right: -40px;
            z-index: 2;
        }
    }

    .destinataire {

        margin: 10px auto 10px 50px;

        img {
            left: -40px;
        }
    }

    .ajouter-piece-jointe {
        float:right;
        margin-top: calc(1.4em + 5px);

        &:active {
            margin-top: calc(1.4em + 5px);
        }
    }

    .piece-jointe-message {
        padding: 5px 9px;
        border-radius: 5px;
        display:inline-block;
        float: left;
        margin: 5px;
        width: 100%;
        position: relative;
        white-space: nowrap;
        background-color: #01a9dc !important;

        span {
            position: absolute;
            left: 26px;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 77%;
            display: inline-block;
            overflow: hidden;
        }
    }

    .action-message {
        .push-vertical {
            margin: 10px 0;
        }
        .btn-messagerie-action {
            width : 270px;
            text-align: center;
        }
    }

    @media only screen and (max-width: 1200px) {

        a.phone {
            display:block !important;
            width: 205px;
            margin-bottom:15px;
            &:active {
                margin-bottom:15px;
            }
        }

        a.desktop {
            display:none !important;
        }

        .expediteur {
            margin: 10px 30px 10px auto;
        }

        .destinataire {
            margin: 10px auto 10px 30px;
        }

        .conversation {
            width: calc( 100% - 5px );
        }

        .informations {
            width:100%;
            columns: 2 150px;
            margin-bottom:15px;
        }

        .ajouter-piece-jointe-phone {
            float:right;
        }
    }

    @media only screen and (max-width: 992px) {
        .piece-jointe-container {
            a {
                span {
                    display:none;
                }
            }
        }
    }

    /* Small Devices, Tablets (480 < screen < 768) [xs] */
    @media only screen and (max-width: 768px) {
        .ajouter-piece-jointe {
            margin-bottom:15px;
            &:active {
                margin-bottom:15px;
            }
        }

    }

    /* Extra Small Devices, Phones (screen < 480) */
    @media only screen and (max-width: 480px) {
        .message {
            img {

            }
        }

        .message {
            .contenu  {
                max-width:100%;
            }
        }

        .piece-jointe-container {
            input.input-file-message {
                padding: 2px;
                height: 28px;
                margin-right:3px;
            }

            a {
                padding: 2px 10px;
                height: 28px;
            }
        }
    }
}
