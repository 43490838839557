.astuce-content {
	padding-right: 40px;

	div.selected {
		i.rotate {
			transform:rotate(180deg);
		}
	}

	.categorie-border {
		border-bottom: 1px solid lightgrey;
		padding-bottom: 20px;
	}

	i.rotate {
		transition: all 0.5s ease;
	}

	.indent {
		margin-left: 40px;
	}

	.btn {
		margin: 5px 0;
	}

	.fa.fa-chevron-down {
		float: right;
	}
}

@media only screen and (max-width: 992px)
{
	.astuce-content {
		padding-right: 20px;

		.indent {
			margin-left: 20px;
		}
	}
}