.compte {
	overflow: auto;

	.left {
		width : 65%;
		display: inline-block;
		float: left;

		.details {
			border: 1px solid lightgrey;
			border-bottom: none;

			.row {
				margin: 0;
				border-bottom: 1px solid lightgrey;

				.libelle, .contenu {
					padding: 10px 20px;
				}
			}

			.row:nth-child(even) {
				background: #F3F3F3;
			}
		}

		.bouton {
			margin-top: 20px;
		}
	}

	.right {
		width : 250px;
		display: inline-block;
		float: right;
		text-align: center;

		img {
			width: 175px;
			height: 175px;
		}

		.controles-image {
			display: inline-block;

			.bouton {
				margin-left: auto;
				margin-right: auto;
				display: block;
				margin-top: 10px;
			}
		}
	}
}


.crop-image {

	.image-container {
		width: 30vw;
		height: 30vw;
		margin-left: auto;
		margin-right: auto;

		img {
			max-width: 100%;
		}
	}
}


.popin {

	.modification-compte {
		text-align: left;
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;

		.row {
			margin: 0;

			.libelle {
				font-weight: bold;
				margin-top: 13px;
			}
		}

		.valider {
			margin: 20px 0;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
}



@media only screen and (max-width: 992px) {
	.compte {

		.left {
			width : 100%;

			.details {

				.libelle {
					border-bottom: none !important;
					font-weight: bold;
					padding-bottom: 0 !important;
					padding-left: 10px !important; 
				}

				.contenu {
					padding-left: 30px !important;
				}
			}
		}

		.right {
			width : 100%;
			margin-bottom: 20px;

			img {
				margin-right: 20px;
			}

			.controles-image {
				margin-top: 25px;
				vertical-align: top;
			}
		}
	}

	.crop-image {

		.image-container {
			width: 70vw;
			height: 70vw;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px;

			img {
				max-width: 100%;
			}
		}

		.bouton {
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
}