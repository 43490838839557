@import url('https://fonts.googleapis.com/css?family=Lobster|Open+Sans');

$ft-open : 'Open Sans';
$ft-lobster : 'Lobster';
$principal-color: #c43827;
html{
	overflow-x: hidden;
}
body.connexion{
	background: white;
	color: black;
	font-family: $ft-open;
	overflow-x: hidden;
	max-width: 100%;

	header{
		padding: 20px;
	}

	.logo-extranet{
		max-height: 66px;
	}
	footer{
		background: #dfdfdf;
		padding: 20px;
		.logo-creacours{
			max-height: 66px;
		}
	}

	.network{
		padding: 30px 0px;
		position: relative;
		ul{
			list-style-type: none;
			padding: 0;
			li{
				display: inline-block;
				margin-right: 5px;
			}
		}
	}
	h1{
		font-family: $ft-lobster;
	}
	h3{
		font-size: 26px;
	}
	.accroche-blog{
		font-size: 15px;
	}
}

.btn-login{
	background: $principal-color;
	color: white;
	font-weight: 700;
	opacity: 1;
	transition: opacity 1s ease;
	&:hover{
		color: white;
		opacity: 0.8;
	}
}
.strong{
	font-weight: 700;
}
.text-underline{
	text-decoration: underline;
}
.connexion-row{
	position: relative;

	&::before{
		background : url('../../img/connexion/background.jpg') center top no-repeat;
		background-size: cover;
		position: absolute;
		z-index: 5;
		content: '';
		min-height: 100%;
		min-width: 120%;
		transform-origin: center center;
		transform: rotate(-2deg) translateX(-50%);
		top: -15px;
		left:50%;
	}
	.content-connexion-box{
		position: relative;
		z-index: 30;
		padding: 45px;
	}
	label {
		color: #fff;
	}
	.connexion-box{
		padding: 10px 40px;
		text-align: center;
		color: #4f4f4f;
		h2{
			margin-top: 5px;
			color: white;
			margin-bottom: 28px;
		}
		input{
			border-radius: 0px;
			margin-bottom: 10px;
		}
		input[type="submit"]{
			padding: 10px;
			position: relative;
		}
	}
}


#regularisation-popin {
	display: none;
}

#a-regulariser{
	a {
		padding: 8px 20px;
		&.inscription{
			margin-right: 35px;
		}
		.fa{
			padding-right: 10px;
		}
	}
	a, a:hover, a:focus {
		text-decoration: none;
	}
	.modal-dialog{
		margin: 200px auto 0;
		.modal-content {
			border-radius: 0;
		}
	}
	.modal-header{
		font-size: 16px;
		h2{
			font-size:25px;
			margin-bottom: 0;
		}
		h3{
			font-size: 18px;
		}
		button.close {
   			color : $black;
   			opacity: 0.8;
			span{
				font-size: 45px;
   				float: right;
   				margin-top: -15px;
   				margin-right: -5px;
   			}
		}
		p {
			margin-bottom: 15px;
    		line-height: 1.5em;
    		font-size: 16px;
    		padding: 10px 20px;
		}

		.espace-eleve {
			display: block;
		}
	}
	.modal-body{
		background-image: url("../../img/connexion/cc_pop_in_desktop.jpg");
    	background-repeat: no-repeat;
    	background-position: center;
    	height: 194px;
	}
}

/*========== Mobile Non First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
	body.connexion{
		.container {
			width: 90%;
		}
	}

	#a-regulariser{
		.modal-header{
			p {
				padding : 0px;
				font-size: 14px;
			}
		}
		.modal-body{
			background-image: url("../../img/connexion/cc_pop_in_mobile.jpg");
	    	background-size: cover;
		}
	}
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
	#a-regulariser {
		.modal-dialog {
	    	max-width: 600px;
		}
	}
	// DESKTOP
	.connexion-row{
		&::before{
			min-height: 105%;
			min-width: 220%;
		}
		.content-connexion-box{
			padding: 20px;
		}
	}
	.red-mobile{
		color: $principal-color;
	}
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

