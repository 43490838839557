.pagination {
    .active	{
        .btn-pagination {
            z-index: 3;
            color: #fff;
            background-color: #337ab7;
            cursor: default;
                margin-left: 0;
            border-radius: 4px;
            position: relative;
            float: left;
            padding: 6px 12px;
            line-height: 1.428571429;
            text-decoration: none;
            border: 1px solid #337ab7;
        }
    }
}
