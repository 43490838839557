.messagerie-search
{
    .form-group.custom-form
    {
        max-width: 100%;

        select.form-control
        {
            width: auto;
            float:left;
            margin: 5px;
        }

        input.form-control
        {
            width: 100%;
        }

        .form-control:last-child
        {
            border-right-width: 1px;
            margin-right: 5px;
        }

        button.form-control
        {
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            left: -12px;
            top: -1px;
            width: 15%;
        }

    }

    .recherche-avancee
    {
        margin-top: 15px;
        padding: 20px 25px;
    }
}