.cv-content {

	.formulaire {

		.libelle {
			font-weight: bold;
			margin-top: 20px;
		}

		.salaire {

			input {
				width: 85%;
				display: inline-block;
				margin-right: 10px;
				margin-top: 0;
			}
		}

		.lettre-motivation {
			
			textarea {
				max-width: 100%;
				min-height: 300px;
			}
		}
	}

	.infos-cv {
		margin-top: 20px;
		padding: 15px;

		.nouveau-cv {
			margin: 15px 0 0 0;
		}

		.details-cv {
			border: 1px solid lightgrey;
			border-bottom: none;
			padding: 0;
			margin-top: 20px;

			.row:nth-child(even) {
				background: #F3F3F3;
			}

			.row {
				padding: 10px;
				margin: 0;
				border-bottom: 1px lightgrey solid;
			}
		}

		.libelle {
			font-weight: bold;
		}

		.contenu-lettre-motivation {
			margin-top: 20px;
			border: 1px solid lightgrey;
			border-radius: 5px;
			padding: 20px;
		}
	}
}

@media only screen and(max-width: 992px)
{
	.cv-content {

		.infos-cv {
			padding: 0px;

			.details-cv-wrapper {
				margin-top: 20px;

				.bouton {
					width: 100%;
					margin: 5px 0;
					height: 50px;
					padding-top: 14px;
					font-weight: bold;

					i {
						margin-top: 0;
					}
				}
			}
		}
	}
}