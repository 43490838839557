.eleve-professeurs {

	.professeur {
		padding: 20px;
		margin-left: 40px;
		margin-right: 40px;
		border-bottom: 1px solid lightgrey;
		position: relative;

		.infos-professeur {

			.left {
				display: inline-block;
				vertical-align: top;
				width: 28%;

				img {
					max-width: 100%;
					border-radius: 50%;
				}
			}

			.right {
				display: inline-block;
				vertical-align: top;
				padding-left: 25px;
				width: 70%;

				.nom-professeur {
					font-size: 18px;
					font-weight: bold;
				}

				.telephone-professeur {
					font-size: 15px;
				}
			}
		}

		.permanence {
			position: static;
		}
	}
}


@media only screen and (max-width: 992px) {

	.eleve-professeurs {

		.professeur {
			width: 100%;
			margin: 0;
			padding: 20px 0;

			.permanence {
				margin-top: 20px;	
			}
		}
	}
}