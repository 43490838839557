.contacts.index {

	.contacts-wrapper {

		.contact {
			display: inline-block;
			padding: 20px;
			border: 1px solid lightgrey;
			border-radius: 5px;
			margin: 10px;

			.nom {
				font-size: 16px;
				font-weight: bold;
			}

			.mail {
				text-decoration: underline;
			}
		}

		@media only screen and (max-width: 992px) {
			.contact {
				display: block;
				border: none;
				border-bottom: 1px solid lightgrey;
				margin: 0;
			}
		}
	}
}