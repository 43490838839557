.gestion-masse-formation{
    padding: 10px 0px;
    .formations{
        max-height: 300px;
        overflow-y: auto;
        border-radius: 2px;
        border: 1px solid grey;
        margin-bottom: 5px;
        & > div {
            height: 36px;
            padding: 2px;
        }

        input[type="checkbox"]{
            display: none;
            & + label{
                height: 100%;
                width: 100%;
                padding: 5px;
                border: 3px solid transparent;
                border-radius: 4px;
                position: relative;
            }
            &:checked + label{
                background: #46a064;
                border-color: #0e7f33;
                padding-left: 30px;
                color: white;
                oveflow: hidden;
                &:before{
                    position: absolute;
                    font-family: FontAwesome;
                    content: "\f00c";
                    top: 0px;
                    left: 3px;
                    font-size:  25px;
                    opacity: 0.8;
                }
            }
        }
    }
}
.gm-formations-lock{
    position: relative;
    vertical-align: middle;
    &:before{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 90;
        background : grey;
        opacity: 0.8;
        font-family: FontAwesome;
        font-size: 120px;
        content: "\f023";
        text-align: center;
        color: white;
        border-radius: 4px;
        top:0;
        left:0;
    }
}
.gestion-masse-correspondance {

    padding: 20px 0px;
    .content-correspondance{
        max-height: 500px;
        overflow-y: auto;
        border: 1px solid grey;
    }
    table{
        th{
            text-align: center;
        }
        tbody{
            td{
                border: 1px solid #eaeaea;
            }
        }
        td{
            padding: 0px;
            &.disabled{
                text-align: center;
                color: #5b5b5b;
                opacity: 0.3;
                font-size: 25px;
                background: #d6d6d6;
                border: 2px solid #bababa;
                border-radius: 3px;
                &:before{
                    font-family: FontAwesome;
                    content: '\f05e';
                }
            }

            label{
                min-height: 100%;
                width: 100%;
            }

            input[type="checkbox"]{
                display: none;
                & + label{
                    color: #991e1e;
                    text-align: center;
                    font-size: 25px;
                    &:before{
                        font-family: FontAwesome;
                        content: "\f00d";
                    }
                }
                &:checked + label{
                    color: #0e7f33;
                    &:before{
                        content: "\f00c";
                    }
                }
            }
        }
    }
    select{
        min-width: 300px;
    }
}
.overlay-success{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(82,165,102,0.7);
    color: white;
    text-align: center;
    display: table;
    z-index: 400;
    .overlay-content{
        display: table-cell;
        vertical-align: middle;
    }
    p{
        font-size: 30px;
    }
    i{
        font-size: 120px;
    }
}