$couleurHover : #E9E9E9;

.offre-emploi.index {

	.titres {
		padding: 10px;
		margin: 0;
		font-weight: bold;
	}

	.offre-emploi {
		padding: 10px;
		border-bottom: 1px solid lightgrey;
		margin: 0;

		.intitule {
			font-weight: bold;
		}
	}

	.offre-emploi:hover {
		background: $couleurHover;
	}

	.ligne-offre-emploi:nth-child(even) {

		.offre-emploi {
			background: #F3F3F3;
		}

		.offre-emploi:hover {
			background: $couleurHover;
		}	
	}
}

.offre-emploi.show {
	overflow: hidden;

	.description {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		border: 1px solid lightgrey;
		padding: 20px;
	}

	.details {

		.right, .left {
			vertical-align: top;
		}

		table {
			margin-left: auto;
			margin-right: auto;
			min-width: 75%;

			tr:nth-child(even) {
				background: #F3F3F3;
			}

			tr {
				padding: 10px;
				border: 1px solid lightgrey;

				.libelle {
					padding: 10px;
					font-weight: bold;
					max-width: 130px;
				}

				.contenu {
					padding: 10px;
					padding-left: 20px;
				}
			}
		}
	}
}

@media only screen and (max-width: 992px) {
	.offre-emploi.show {
		overflow: hidden;

		.description {
			width: 100%;
			padding: 10px;
		}

		.details {

			table {
				width: 100%;

				.libelle {
					display: block;
					padding: 5px 10px !important;
					padding-bottom: 0px !important;
				}

				.contenu {
					display: block;
					padding: 5px 10px !important;
				}
			}

			.right {

				tr:first-child {
					border-top: none;
				}
			}

			.left, .right {
				padding: 0;
			}
		}
	}

	.offre-emploi.index {

		.offre-emploi {

			.intitule, .nom-entreprise {
				margin-top: 10px;
			}
		}
	}
}