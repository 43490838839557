/* 
    Created on : 28 avr. 2016, 15:39:06
    Author     : l.rouquette
*/

.aide {
    display: inline-block;
    position: relative;
}

span.contenu-aide {
    visibility: hidden;
    position: absolute;
    display: inline-block;
    color: white;
    background-color: rgba(67,67,67, 1);
    padding: 5px;
    border-radius: 5px;
    min-width: 100px;
}

.aide:hover span.contenu-aide {
    visibility: visible;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 50;
}

.aide:hover span.contenu-aide::after
{
  content: "";
  position: absolute;
  z-index: 1000;
  bottom: -8px;
  left: 50%;
  margin-left: -8px;
  border-top: 8px solid rgba(67,67,67, 1);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 0;
}
