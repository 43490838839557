$white : #FFF;
$black : #000;

$white-broken : #fbfbfb;

$blue : #1e5983; /* couleur status message */
$blue-dark-1 : rgba(87,89,104,0.5);
$blue-dark-2 : #383a4c; /* Couleur texte */

$cyan : #00bff3; /* Couleur fond annonce important */
$purple : #7859c6; /* Couleur fond annonce niveau 2 */

$grey : #B1B2B1;
$grey-dark-1 : #576067; /* Couleur fond bouton */
$grey-light-1 : #E8E8E8; /* Couleur fond footer */
$grey-light-2 : #E5E5E5; /* Couleur fond bouton clair */
$grey-light-3 : #F3F3F3; /* Couleur de fond */
$grey-light-4 : #CACACA;

$grey-dark-1 : #888888;

$yellow : #E7EF67;

$orange : #EC8F2B;

$green : #75B56B;

$pink : #F38CE8;

$red : #E14747;


$rougeCreacours : #c43827;
$grisCreacours : #212221;
$roseCreacours : #da7780;
$grisMenu : #464444;

.grey { color : $grey !important; }
.bg-grey { background-color: $grey !important; }
.border-grey { border-color: $grey !important; }

.grey-dark-1 { color : $grey-dark-1 !important; }
.bg-grey-dark-1 { background-color: $grey-dark-1 !important; }
.border-grey-dark-1 { border-color: $grey-dark-1 !important; }

.grey-light-1 { color : $grey-light-1 !important; }
.bg-grey-light-1 { background-color: $grey-light-1 !important; }
.border-grey-light-1 { border-color: $grey-light-1 !important; }

.grey-light-2 { color : $grey-light-2 !important; }
.bg-grey-light-2 { background-color: $grey-light-2 !important; }
.border-grey-light-2 { border-color: $grey-light-2 !important; }

.grey-light-3 { color : $grey-light-3 !important; }
.bg-grey-light-3 { background-color: $grey-light-3 !important; }
.border-grey-light-3 { border-color: $grey-light-3 !important; }

.grey-light-4 { color : $grey-light-4 !important; }
.bg-grey-light-4 { background-color: $grey-light-4 !important; }
.border-grey-light-4 { border-color: $grey-light-4 !important; }

.grey-dark-1 { color : $grey-dark-1 !important; }
.bg-grey-dark-1 { background-color: $grey-dark-1 !important; }
.border-grey-dark-1 { border-color: $grey-dark-1 !important; }

.white { color : $white !important; }
.bg-white { background-color: $white !important; }
.border-white { border-color: $white !important; }

.btn.white:hover { color: $white; opacity: 0.9 }
.btn.disabled.white:hover { color: $white; opacity: 0.65 }

.white-broken { color : $white-broken; }
.bg-white-broken { background-color : $white-broken; }
.border-white-broken { border-color : $white-broken; }

.black { color : $black !important; }
.bg-black { background-color: $black !important; }
.border-black { border-color: $black !important; }

.blue { color : $blue !important; }
.bg-blue { background-color: $blue !important; }
.border-blue { border-color: $blue !important; }

.blue-dark-1 { color : $blue-dark-1 !important; }
.bg-blue-dark-1 { background-color: $blue-dark-1 !important; }
.border-blue-dark-1 { border-color: $blue-dark-1 !important; }

.blue-dark-2 { color : $blue-dark-2 !important; }
.bg-blue-dark-2 { background-color: $blue-dark-2 !important; }
.border-blue-dark-2 { border-color: $blue-dark-2 !important; }

.cyan { color : $cyan !important; }
.bg-cyan { background-color: $cyan !important; }
.border-cyan { border-color: $cyan !important; }

.purple { color : $purple !important; }
.bg-purple { background-color: $purple !important; }
.border-purple { border-color: $purple !important; }

.grey { color : $grey !important; }
.bg-grey { background-color: $grey !important; }
.border-grey { border-color: $grey !important; }

.grey-dark-1 { color : $grey-dark-1 !important; }
.bg-grey-dark-1 { background-color: $grey-dark-1 !important; }
.border-grey-dark-1 { border-color: $grey-dark-1 !important; }

.grey-light-1 { color : $grey-light-1 !important; }
.bg-grey-light-1 { background-color: $grey-light-1 !important; }
.border-grey-light-1 { border-color: $grey-light-1 !important; }

.grey-light-1-no-imp { color : $grey-light-1; }
.bg-grey-light-1-no-imp { background-color: $grey-light-1; }
.border-grey-light-1-no-imp { border-color: $grey-light-1; }

.grey-light-2 { color : $grey-light-2 !important; }
.bg-grey-light-2 { background-color: $grey-light-2 !important; }
.border-grey-light-2 { border-color: $grey-light-2 !important; }

.grey-light-3 { color : $grey-light-3 !important; }
.bg-grey-light-3 { background-color: $grey-light-3 !important; }
.border-grey-light-3 { border-color: $grey-light-3 !important; }

.grey-light-4 { color : $grey-light-4 !important; }
.bg-grey-light-4 { background-color: $grey-light-4 !important; }
.border-grey-light-4 { border-color: $grey-light-4 !important; }

.grey-dark-1 { color : $grey-dark-1 !important; }
.bg-grey-dark-1 { background-color: $grey-dark-1 !important; }
.border-grey-dark-1 { border-color: $grey-dark-1 !important; }

.yellow { color : $yellow !important; }
.bg-yellow { background-color: $yellow !important; }
.border-yellow { border-color: $yellow !important; }

.orange { color : $orange !important; }
.bg-orange { background-color: $orange !important; }
.border-orange { border-color: $orange !important; }

.green { color : $green !important; }
.bg-green { background-color: $green !important; }
.border-green { border-color: $green !important; }

.pink { color : $pink !important; }
.bg-pink { background-color: $pink !important; }
.border-pink { border-color: $pink !important; }

.red { color : $rougeCreacours !important; }
.bg-red { background-color: $rougeCreacours !important; }
.border-red { border-color: $rougeCreacours !important; }


.bg-green-p { background-color : #5CB85C !important; }