tr{
	&.exercice {
		border : {
			left : 6px solid transparent;
		}
		.btn-download-document{
			background: $blue;
			color: white;
			border : {
				color: $blue;
			}
		}
		&.downloaded{
			border : {
				color:  $blue;
			}
			.btn-download-document{
				background: white;
				color: $blue;
				border : {
					color: $blue;
				}
			}
		}
	}
}
