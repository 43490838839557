.livechat.edit
{
  .no-border
  {
    border-radius: 3px;
    border: 0;
  }
  label {
    margin-top: 20px;
  }
}