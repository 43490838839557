.devoir-moderateur {
  table {
    width: calc(100% - 1px);
  }

  th
  {
    text-align: center;
    h5
    {
      font-weight: 600;
    }
  }

  tbody
  {
    tr:nth-child(odd)
    {
      background-color: #EEE;
      border-top-width: 1px;
      border-bottom-width: 1px;
      border-top-style: solid;
      border-bottom-style: solid;
    }

    td
    {
      text-align: center;
      padding: 5px;
      border-right-width: 1px;
      border-right-style: solid;
    }

    td:first-child
    {
      padding-left: 10px;
    }

    td:last-child
    {
      padding-right: 10px;
    }
  }
  .petit.italique{
      font-style: italic;
      font-size: 12px;
  }
}

.popin {

  .modification-note {

    .etat-note {
      margin: 20px 0;

      .etat, .note {
        width: 175px;
        display: inline-block;
        vertical-align: top;
      }

      .note {
        margin-left: 20px;
      }
    }

  }
}

.modal-commentaire-prof{
  position: absolute;
  margin-left: 15px;
  cursor: pointer;
}