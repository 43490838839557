$gris : #576067;
$vert : #44B332;
$orange : #FF6D1D;
$bleu : #1E5983;

.devoirs-content {
	text-align: center;

	.contenu-commentaire {
		display: inline-block;
		width: 100%;
		min-height: 50px;
		padding: 5px;
		margin-top: 5px;
	}

	h1 {
		text-align: center;
	}

	.nouveau {
		border: 1px solid;
		border-radius: 5px;
		display: inline-block;
		padding: 5px 10px;
		margin: 5px;
	}

	.selection-options-devoirs {
		text-align: center;
		margin-top: 30px;
		margin-bottom: 30px;

		select {
			width: 250px;
			display: inline-block;
			margin: 0 20px;
		}

		.formations, .colis {
			padding-top: 4px;
		}

		// .formations {
		// 	margin-right: 30px;
		// }

		a {
			width: 100%;
			height: 100%;
			display: block;
		}
	}

	.legende {
		width: 650px;
		height: 95px;
		border-style: solid;
		border-width: 1px;
		border-radius: 15px;
		margin-left: auto;
		margin-right: auto;
		position: relative;

		.fermerOuvrirLegendeEleve{
			cursor: pointer;
		}

		.no-item {
			width: 100%;
			text-align: center;
			padding: 10px 20px;
		}

		.fermer-legende {
			display: inline-block;
			position: absolute;
			top: 0;
			right: 7px;
		}

		.legende-titre {
			font-size: 20px;
			display: inline-block;
			width: calc(35% - 15px);
			height: 100%;
			float: left;
			text-align: center;
			padding-top: 24px;
		}

		.legende-item {
			display: inline-block;
			width: 20%;
			height: 100%;
			float: left;
			padding: 10px 5px;
			text-align: center;

			.icone {
				font-size: 18px;
				display: block;
			}

			.icone.moyenne {
				i {
					font-size: 14px;
					display: block;
				}
				span {
					font-size: 10px;
					display: block;
				}
			}

			.texte-legende {
				font-size: 12px;
				display: block;
			}
		}
	}

	.legende.no-legend {
		width: 200px;
		height: auto;
	}

	.correction-en-cours, .recu, .corrige {

		.icone-etat, .message-etat {
			color: $gris;
		}
		border-color: $gris !important;
	}

	.a-remettre {

		.icone-etat, .message-etat {
			color: $vert;
		}
		border-color: $vert !important;
	}

	.a-refaire, .inexploitable, .triche {

		.icone-etat, .message-etat {
			color: $orange;
		}
		border-color: $orange !important;
	}

	.a-telecharger {
		.icone-etat, .message-etat {
			color: $bleu;
		}
		border-color: $bleu !important;
	}

	//Tableau en version desktop
	.tableau-devoirs {
		width: 95%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		margin-top: 30px;

		.titres {

			.titre {
				text-align: center;
				height: 30px;
			}
		}

		.body {

			tr {
				border-bottom: 1px solid #B4B4B4;
				height: 125px;
			}

			.date {
				margin-bottom: 5px;
			}

			td {
				padding: 10px 10px;
				height: 125px;
			}

			.content-to-top {
				vertical-align: top;
			}

			.ecrire-message {

				i{
					font-size: 30px;
				}
			}

			.matiere {
				text-align: center;
			}

			.devoir {
				text-align: center;
				min-width: 160px;
				margin: 3px;
			}

			.enonce {
				text-align: center;
			}

			.devoirs-remis {
				text-align: center;
				min-width: 150px;

				.devoir-remis{
					margin: 15px 0;
					min-width: 135px;
				}

				.date, .commentaire {
					display: block;
				}

				.commentaire {
					text-decoration: underline;
					margin-top: 5px;
					margin-bottom: 10px;
				}

				.commentaire:hover {
					cursor: pointer;
				}

				.bouton-historique-devoirs {
					margin: 10px 0;
				}
			}

			.commentaire-professeur {
				position: relative;
				width: 26px;
			}

			.notification {
				display: inline-block;
				position: absolute;
				top: -5px;
				right: -7px;
				text-align: center;
				border-radius: 50%;
				height: 10px;
				width: 10px;
				font-size: 7px;
			}

			.correction {
				text-align: center;
				width: 320px;

				.commentaire-professeur-uniquement {
					i {
						font-size: 30px;
					}

					.notification {
						top: -2px;
						right: -5px;
						height: 14px;
						width: 14px;
						font-size: 10px;
					}
				}

				.date {
					display: block;
				}

				.cartouche-devoir-numerique {
					width: 290px;
				}

				.cartouche {
					height: 40px;
					min-width:325px;
					text-align: center;
					border-width: 1px;
					border-style: solid;
					border-radius: 20px;
					margin-left: auto;
					margin-right: auto;
					padding: 6px 4px 0 10px;

					i {
						font-size: 22px;
					}

					span {
						margin: 0 7px;
						display: inline;
					}

					span:hover {
						cursor: pointer;
					}

					.note:hover {
						cursor: initial;
					}

					.moyenne-generale {
						display: inline-block;
						float: right;

						i, span {
							display: block;
						}

						i {
							font-size: 16px;
						}

						.note-moyenne-generale {
							font-size: 10px;
						}
					}

					.moyenne-generale a:hover {
						text-decoration: none;
					}
				}

				.aide.devoir-corrige .bubble{
					position: relative;
				}
			}

			.etat {
				text-align: center;
				width: 137px;
				border-left: 1px solid #B4B4B4;

				span {
					display: block;
					width: 100%;
				}

				.icone-etat {
					font-size: 30px;
				}

				.message-etat {
					font-weight: bold;
					font-size: 16px;
				}

				.professeur {

				}
			}
		}
	}

	//Tableau en version mobile

	.tableau-devoirs-phone {
		text-align: left;

		.sujet-devoir:last-child {
			border-bottom: solid 1px lightgrey;
		}

		.sujet-devoir {
			border-top: solid 1px lightgrey;

			.header-sujet-devoir {
				position: relative;
				border-left: 5px solid;
				padding: 10px 0 10px 10px;
				margin-right: 15px;

				.partie-gauche {
					display: inline-block;
					width: 80%;
					padding: 0 10px;

					.nom-sujet-devoir {
						font-weight: bold;
					}

					.etat {

						.icone-etat {
							font-size: 18px;
						}

						.message-etat {
							margin-left: 5px;
							font-weight: bold;
						}
					}
				}

				.chevron {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 0;
					font-size: 25px;
				}

				.date {
					position: absolute;
					right: 0;
					bottom: 10px;
				}
			}
		}

		.copies-eleve {
			padding: 15px;
			border-top: solid 1px lightgrey;
			position: relative;

			.blank {
				margin: 40px 0;
			}

			.ecrire-professeur {
				width: 40px;
				height: 40px;
				text-align: center;
				padding-top: 9px;
				position: absolute;
				top: 15px;
				right: 15px;

				i {
					font-size: 25px;
				}
			}

			.note {
				margin-top: 10px;
			}

			.moyenne-eleves {
				margin-bottom: 10px;
			}

			.commentaire-eleve, .commentaire-professeur {
				margin-bottom: 5px;

				span {
					display: block;
				}
			}

			.boutons {
				margin-top: 10px;

				.aide {
					display: block;
				}

				.bouton {
					width: 100%;
					margin-top: 15px;
					border-color: grey !important;
					height: 50px;
					padding-top: 10px;

					i {
						margin-left: 10px;
						margin-right: 10px;
						margin-top: 0;
						font-size: 25px;
					}

					span:not(.contenu-aide) {
						font-size: 15px;

						display: inline !important;

						b {
							display: inline !important;
						}
					}

					a {
						padding: 0;
					}
				}
			}
		}
	}
}




//Disposition des popins

.popin {

	.modal-header {
		text-align: center;
	}

	.modal-body {
		text-align: center;
	}

	.historique-devoirs {
		overflow: auto;

		.item {
			display: block;
			margin-bottom: 20px;
			border-color: lightgrey;
			border-bottom-style: solid;
			border-bottom-width: 1px;

			p.grey {
					font-weight: bold;
			}

			.infos-devoir {
				width: 25%;
				text-align: center;
				display: inline-block;
				vertical-align: middle;

				.date {
					font-weight: bold;
					display: block;
				}

				.bouton {
					margin-top: 5px;
				}
			}

			.commentaire {
				display: inline-block;
				width: 50%;
				text-align: left;
				padding-left: 20px;
				vertical-align: top;
				text-decoration: none;

				.contenu-commentaire {
					margin-top: 5px;
					min-height: 50px;
					margin-bottom: 20px;
				}
			}

			.infos-professeur {
				display: inline-block;
				width: 24%;
				vertical-align: top;
				text-align: left;

			}
		}
	}
	.commentaire-professeur {
		text-align: left;
   		/*width: 80%;*/
   		margin: 0 auto;
	}
	.commentaire-eleve, .commentaire-professeur {
		padding: 10px 20px;

		.libelle {
			font-weight: bold;
		}

		.contenu-commentaire {
			margin-top: 5px;
		}
	}

	.ne-plus-afficher-tuto {
		text-align: center;

		.bouton {
			text-align: center;
			width: 163px;
			height: 30px;
			font-size: 12px;
			padding-top: 5px;
		}
	}

	.depot-devoirs-titre {

	}

	.depot-devoir-contenu {
		margin-bottom: 20px;

		.aide {
				display: inline-block !important;
		}

		.afficher-tuto {
			margin-left: 10px;
		}

		.bulle-interrogation {
			display: block;
			border-radius: 50%;
			width: 20px;
			height: 20px;
		}

		.etape-header {
			margin-bottom: 10px;

			.titre-etape {
				display: block;
				font-size: 15px;
				font-weight: bold;
			}
		}

		.etape {
			margin: 0;
			width: 33%;
			display: inline-block;
			padding: 10px 20px;
			vertical-align: top;

			.aide {
				display: inline-block !important;
			}

			.numero-etape {
				display: block;
				font-size: 30px;
				height: 42px;
				width: 42px;
				margin-left: auto;
				margin-right: auto;
				border-radius: 50%;
				margin-bottom: 15px;
			}
		}

		.etape-3 {

			.regle-nombre-fichiers, regle-enonce {
				margin-bottom: 20px;
			}

			.infos-copie, .document-interdit {
				margin-top: 10px;

				.nom-fichier {
					font-weight: bold;
				}

				.commentaire-eleve-input {
					height: 100px;
					max-width: 400px;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}

	.modification-devoir {

		.input-file, .submit-form {
				text-align: center;
			}

		.modification-fichier {
			vertical-align: top;
			display: inline-block;
			width: 50%;
			padding: 20px;
			// border-right-style: solid;
			// border-right-width: 1px;

			.infos-copie {
				margin-top: 20px;

				.nom-fichier {
					font-weight: bold;
				}
			}
		}

		.modification-fichier:before {
			content: "";
			position: absolute;
			right: 50%;
			top: 10%;
			height: 80%;
			border-right: 1px solid lightgray;
		}

		.modification-commentaire {
			vertical-align: top;
			display: inline-block;
			width: 49%;
			padding: 20px;

			.commentaire-eleve-input {
				height: 100px;
			}
		}
	}
}


//Les rotations de chevrons
.header-sujet-devoir.more {

	i.rotate {
		transform:rotate(180deg);
	}
}
.header-sujet-devoir {

	i.rotate {
		transition: all 0.5s ease;
	}
}


@media only screen and (min-width: 1440px) {
}

/* Large Devices, Wide Screens (1200 < screen < 1440) [md] */
@media only screen and (max-width: 1440px) {
}

/* Large Devices, Wide Screens (992 < screen < 1200) [md] */
@media only screen and (max-width: 1200px) {
	td.etat {
		max-width:80px;
	}
	td.correction {
		max-width:130px;
		height:100px;
	}
	.cartouche-devoir-numerique {
		max-width: 135px;

		span.note, span.telecharger-correction {
			display:block;
		}

		.partie-un-colonne {
			max-width:100%;
			display:inline-block !important;
		}

		.partie-deux-colonne {
			max-width:100%;
			display:inline-block !important;

		}

		.note-moyenne-generale {
			width:0;
		    position: relative;
		   left: -13px;
		}
	}

	.devoirs-content .tableau-devoirs .body .correction .cartouche {
		min-width: 50px;

		height:auto;
	}

	.devoirs-content .tableau-devoirs .body .correction .cartouche i {
		padding:3px;
	}

}

/* Ecran en dessous de 1024 (screen < 1024) */
@media only screen and (max-width: 1024px) {
}
/* Medium Devices, Desktops (768 < screen < 992) [sm] */
@media only screen and (max-width: 992px) {
	.selection-options-devoirs {
		margin-top: 30px !important;

		select {
			margin-bottom: 20px !important;
		}
	}

	.popin {

		.historique-devoirs {
			text-align: left;

			.bouton {
				width: 100%;
				padding-top: 10px;
				margin-bottom: 10px;
				height: 50px;

				i {
					margin-top: 0;
					font-size: 25px;
				}

				span {
					font-size: 18px;
				}
			}

			.infos-devoir {
				width: 100% !important;
			}

			.commentaire {
				width: 100% !important;
				padding-left: 0 !important;

				.contenu-commentaire {
					min-height: 0 !important;
				}
			}

			.infos-professeur {
				width: 100% !important;
				margin-left: 0 !important;
			}
		}

		.depot-devoir-contenu {

			.etape {
				width: 100%;
			}
		}

		.modification-devoir {

			.modification-fichier:before {
				display: none;
			}

			.modification-fichier {
				width: 100%;
			}

			.modification-commentaire {
				width: 100%;
			}
		}
	}
}
/* Small Devices, Tablets (480 < screen < 768) [xs] */
@media only screen and (max-width: 768px) {
}
/* Extra Small Devices, Phones (320 < screen < 480) */
@media only screen and (max-width: 480px) {
	.devoirs-content .selection-options-devoirs .formations {
	    margin-right: 10px;
	}
}
/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
}


/*MODERATEUR*/
.content.devoir-moderateur {
	a.trie-entete {
		h5 {
			position : relative;
			.fa-sort-desc, .fa-sort-asc {
				position : absolute;
				right: 10px;
	    		top: 0px;
	    		color: #D0D0D0;
	    		&.active {
	    			color : $headings-color;
	    		}
			}
		}

		&#dateRemise {
			min-width: 95px;
			display : block;
		}
	}
}


//  _____      _                      _                    _
// |  __ \    | |                    | |                  | |
// | |__) |___| | _____   _____    __| | ___   _ __   ___ | |_ ___
// |  _  // _ \ |/ _ \ \ / / _ \  / _` |/ _ \ | '_ \ / _ \| __/ _ \
// | | \ \  __/ |  __/\ V /  __/ | (_| |  __/ | | | | (_) | ||  __/
// |_|  \_\___|_|\___| \_/ \___|  \__,_|\___| |_| |_|\___/ \__\___|
//

.releve-notes {
	@media only screen and (max-width: 480px) {
		.aide {
			min-width:100%;
		}
		a, button {
			margin-top: 10px;
			width:100%;

		}
	}
}
