#version{
	position:fixed;
	top:85%;
	right:0px;
	background-color:#383a4c;
	padding:20px 10px 3px 30px;
	opacity:0.85;
	z-index:10;
	p{
		color:white;
		width:280px;
		font-size:18px;
	}
	span.close{
		position:relative;
		top:-16px;
		right:0px;
		color:white;
		text-shadow:none;
	}
	&:hover{
		transition: opacity 0.5s;
		opacity:1;
		cursor:pointer;
	}
}


.formulaire-version{
	.error{
		color:red;
	}
	.success{
		color:green;
	}
}