.demande-document-page {
	#ouverture-inscrit {
		display: none;
	}

	.warning .fa {
		font-size: 60px;
		color: $rougeCreacours;
	}

	#pas-inscrit .modal-body {
		background-image: url('../../img/demande_document/popup-mobile.jpg');
		min-height: 190px;
		background-repeat: no-repeat;
		@media screen and (min-width:768px) {
			background-image: url('../../img/demande_document/popup-desktop.jpg');
		}
	}
}

.demande-document-content {

	.document-container {
		padding: 20px 20px 20px 0;
		h3 {
			margin-bottom: 30px;
		}
		.carte-scolaire{
			text-align: left;
			color: $rougeCreacours;
			font-size: 14px;
			font-weight: normal;
			h3 {
				font-weight: 600;
				font-size: 14px;
				color: $rougeCreacours;
				margin-bottom: 10px;

			}
		}
		.action-btn{
			text-align: center;
			margin-top: 20px;
		}

		.adresse {
			border: 1px solid lightgrey;
			display: inline-block;
			padding: 10px;
			border-radius: 5px;
			margin: 20px;
		}

		.warning {
			margin: 20px 0;
		}

		.formulaire {
			text-align: left;

			.row {
				padding: 10px 0;
				margin: 0;

				.libelle {
					font-weight: bold;

					.aide {
						display: inline-block;
					}

					@media only screen and (max-width: 992px) {
						.aide {
							display: none;
						}
					}

					.ouvrir-aide {
						width: 20px;
						height: 20px;
						border-radius: 50%;
						display: block;
						text-align: center;
					}

					.ouvrir-aide:hover {
						cursor: pointer;
					}
				}

				.civilite {

					@media only screen and (min-width: 992px) {
						width: 15%;
						display: inline-block;
					}
				}
				.nom {

					@media only screen and (min-width: 992px) {
						width: 84%;
						display: inline-block;
						vertical-align: top;
					}
				}
			}
		}
	}
}