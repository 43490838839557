.nouveau-message {
	.select-eleve{
		margin-bottom : 35px;
	}
	.plan-etude, .info-plan-etude{
		display: none;
	    color:black;
	}

	.info-plan-etude {
    	padding-top:10px;
	}

	.plan-etude {
	    padding-top:10px;
	    a {
	        font-weight:bold;
	        color:#1e5983;
	        text-decoration: underline;
	    }
	}

	.btn-envoyer-message {
		margin-top: 15px;
		&:active {
			margin-top: 15px;
		}
	}

	.piece-jointe-container {
        input.input-file-message {
            height: 36px;
            margin-right:10px;
        }
		button {
			background-color:#E14747 !important;
			color:white;
		}
        a {
            height: 36px;
        }
    }

	.piece-jointe-desktop {
		display:block;
		float:right;
		margin-top: calc(1.4em + 5px);
	}

	.piece-jointe-phone {
		display:none;
	}


	/* Large Devices, Wide Screens (992 < screen < 1200) [md] */
	@media only screen and (max-width: 1200px) {

	}

	/* Ecran en dessous de 1024 (screen < 1024) */
	@media only screen and (max-width: 1024px) {

	}
	/* Medium Devices, Desktops (768 < screen < 992) [sm] */
	@media only screen and (max-width: 992px) {
		.piece-jointe-desktop {
			display:none;
		}

		.piece-jointe-phone {
			display:block;
			float:right;
			margin-bottom: 15px;
		}

		.piece-jointe-container {
			button {
				span {
					display:none;
				}
			}
		}

	}

	@media only screen and (max-width: 768px) {
		.ajouter-piece-jointe {
			margin-bottom:15px;
		}

		.piece-jointe-container {
			button {
				margin-top:3px;
			}
		}

	}

	/* Extra Small Devices, Phones (320 < screen < 480) */
	@media only screen and (max-width: 480px) {
		.piece-jointe-container {
            input.input-file-message {
	            padding: 2px;
	            height: 28px;
	            margin-right:3px;
            }

            button {
           		padding: 2px 10px;
	            height: 28px;
	            position: relative;
	            top:-2px;
            }
        }
	}

	/* Custom, iPhone Retina */
	@media only screen and (max-width: 320px) {

	}
}

