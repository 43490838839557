$couleurFacebook: #3e5b98;
$couleurTwitter: #4da7de;
$couleurInstagram: #d93175;

.footer .fa {
	color: white;
}

.fa-facebook {
	background: $couleurFacebook;
}
.fa-twitter {
	background: $couleurTwitter;
}
.fa-instagram {
	background: $couleurInstagram;
}
